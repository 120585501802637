/*
 * This file contains styling for the site's home page.
 */


/*
 * Import Bootstrap's functions, variables, and mixins so that we can use them
 * below.
 */
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

.home-title-row {
  @include make-row;
  @extend .text-center;

  .home-title {
    @include make-col-ready();
    @include make-col(12);
  }
}

.home-sections-row {
  @include make-row;

  .home-section {
    @include make-col-ready();
    @include make-col();
  }
}

.about-me-divider {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.about-me-row {
  @include make-row;

  .about-me {
    @include make-col-ready();
    @include make-col(12);
    color: $gray-900;
  }

  // Narrow the section on large devices, just because it looks nicer.
  @include media-breakpoint-up(lg) {
    .about-me {
      @include make-col(7);
    }
  }
}
