/*
 * This file contains styling for the site's `/blog` pages.
 *
 * It is a recreation of Bootstrap's sample "Blog Theme", from
 * <http://getbootstrap.com/examples/blog/>.
 */


/*
 * Import Bootstrap's functions, variables, and mixins so that we can use them
 * below.
 */
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";


/*
 * Blog Post List/Frontpage
 */
.blog-intro-row {
  @include make-row;

  .blog-intro {
    @include make-col-ready(); 
    @include make-col(12);
  }
}

ol.blog-posts-row {
  @include make-row;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  > li {
    @include make-col-ready(); 
    @include make-col(12);
    padding-left: 0;

    h2 {
      @extend .h3;
    }

    ol.blog-posts-for-year {
      list-style: none;
      margin-left: 0;
      padding-left: 0;

      > li {
        margin-bottom: 1rem;
        padding-left: 0;
        @extend .card;
        // TODO: try enabling this once Boostrap 5.2 is available.
        //@extend .text-bg-secondary;

        div.summary {
          @extend .card-body;

          h3 {
            @extend .h4;

            a {
              color: $black;
            }
          }

          .meta {
            @extend .card-subtitle;
            @extend .mb-2;
            @extend .text-muted;
            color: $gray-600;
          }

          .description {
            @extend .card-text;
          }

          a.read-more {
            @extend .btn;
            @extend .btn-primary;
          }
        }
      }
    }
  }
}

p.blog-rss-subscribe {
  margin-top: 3rem;
  border-top: 1px solid $gray-800;
  padding-top: 1rem;
}


/*
 * Blog posts.
 */
.blog-post-meta {
  color: $gray-600;
}
